// More Info
// @azure/msal-react used for authentication.
// See https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-react for more details

// react-router-dom used for navigation.
// See https://reactrouter.com/web/guides/quick-start for more details

// swagger-ui-react used to integrate API docs.
// See https://www.npmjs.com/package/swagger-ui-react for more details

import { Suspense, lazy } from "react";
import { Box, Flex } from "@chakra-ui/layout";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Switch, Route, Redirect } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import TermsOfUsePage from "./pages/TermsOfUsePage";
import json from "./swagger.json";
import json_qa from "./swagger_qa.json";
import json_pr from "./swagger_pr.json";

import "swagger-ui-react/swagger-ui.css";
import { useTranslation } from "react-i18next";

// Code Splitting. See https://reactjs.org/docs/code-splitting.html#route-based-code-splitting for more details
const DatasetCataloguePage = lazy(() => import("./pages/DatasetCataloguePage"));
const DatasetDetailsPage = lazy(() => import("./pages/DatasetDetailsPage"));
const SwaggerUI = lazy(() => import("swagger-ui-react"));

export default function App() {
  const { t } = useTranslation("common");
  const spec = process.env.REACT_APP_ENV === "pr"? json_pr: 
  process.env.REACT_APP_ENV === "qa" ? json_qa : json;
  return (
    <Flex h="100vh" margin="0" direction="column">
      <Header />
      <Suspense
        fallback={
          <Box px={10} pt={5}>
            {t("Loading...")}
          </Box>
        }
      >
        <Switch>
          <Route path="/callback">
            <AuthenticatedTemplate>
              <Redirect to="/datasets" />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Redirect to="/" />
            </UnauthenticatedTemplate>
          </Route>
          <Route exact path="/datasets">
            <AuthenticatedTemplate>
              <DatasetCataloguePage />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Redirect to="/" />
            </UnauthenticatedTemplate>
          </Route>
          <Route path="/datasets/:id">
            <AuthenticatedTemplate>
              <DatasetDetailsPage />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Redirect to="/" />
            </UnauthenticatedTemplate>
          </Route>
          <Route path="/apidocumentation">
            <AuthenticatedTemplate>
              <SwaggerUI spec={spec} supportedSubmitMethods={[]} />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Redirect to="/" />
            </UnauthenticatedTemplate>
          </Route>
          <Route path="/termsofuse">
            <TermsOfUsePage />
          </Route>
          <Route exact path="/">
            <LoginPage />
          </Route>
        </Switch>
      </Suspense>
      <Footer />
    </Flex>
  );
}
