import {
  Divider,
  Box,
  Text,
  Stack,
  Center,
  AspectRatio,
  VStack,
} from "@chakra-ui/layout";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StyledButton } from "../components/StyledButton";

import getting_started from "../resources/getting_started.jpg";

const LoginPage = () => {
  const { t } = useTranslation("common");
  const history = useHistory();

  const goToDatasetCataloguePage = () => {
    history.push(`/datasets`);
  };

  return (
    <Stack>
      <AspectRatio ratio={[9 / 21, 40 / 60, 50 / 50, 21 / 9]}>
        <Box
          pos="relative"
          bg="brand.darkblue"
          _before={{
            content: '""',
            bgImage: getting_started,
            bgSize: "cover",
            pos: "absolute",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            opacity: 0.5,
          }}
        >
          <VStack zIndex="1" textAlign="center" p="4">
            <Text
              color="white"
              fontWeight="bold"
              fontSize={["2xl", "3xl", "4xl"]}
            >
              {t("Moneris Data Market")}
            </Text>
            <Divider w="30%" />
            <Text
              color="white"
              fontSize={["lg", "xl", "2xl"]}
              w={["100%", "70%", "50%"]}
            >
              {t("loginPage.description")}
            </Text>
            <AuthenticatedTemplate>
              <Center>
                <StyledButton onClick={goToDatasetCataloguePage}>
                  {t("Explore dataset catalogue")}
                </StyledButton>
              </Center>
            </AuthenticatedTemplate>
          </VStack>
        </Box>
      </AspectRatio>
    </Stack>
  );
};

export default LoginPage;
