import { Box, Text, VStack, OrderedList, ListItem } from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";
import { HStack, Stack } from "@chakra-ui/react";

const TermsOfUsePage = () => {
  const { t } = useTranslation("common");

  return (
    <Box px="10">
      <VStack py="4">
        <Text>{t("termsOfUse.heading")}</Text>
        {/* <Text>{t("Terms of Use")}</Text> */}
        {/*<Text textDecoration="underline">{t("termsOfUse.important")}</Text>*/}
      </VStack>
      <Text>{t("termsOfUse.description")}</Text>
      <OrderedList spacing="4" pb="4">
        <ListItem>
        <HStack pt="4">
          <Text fontWeight="bold">{t("termsOfUse.useofsiteandservices")}</Text>
          </HStack>
          <Stack spacing="4">
          <Text>{t("termsOfUse.useofsiteandservices_a")}</Text>
          <Text>{t("termsOfUse.useofsiteandservices_b")}</Text>
          <Text>{t("termsOfUse.useofsiteandservices_c")}</Text>
          <Text>{t("termsOfUse.useofsiteandservices_d")}</Text>
          <Text>{t("termsOfUse.useofsiteandservices_e")}</Text>
          <Text>{t("termsOfUse.useofsiteandservices_f")}</Text>
          </Stack>          
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">
            {t("termsOfUse.conduct")}
          </Text>          
          <HStack spacing="4">
          <Text>{t("termsOfUse.conduct_a")}</Text>  
          </HStack>        
          <Stack pl="4" pt="4" spacing="4">
          <Text>{t("termsOfUse.conduct_a_i")}</Text>
          <Text>{t("termsOfUse.conduct_a_ii")}</Text>
          <Text>{t("termsOfUse.conduct_a_iii")}</Text>
          </Stack>
          <HStack pt="4" spacing="4">
          <Text>{t("termsOfUse.conduct_b")}</Text>  
          </HStack>        
          <Stack pl="4" pt="4" spacing="4">
          <Text>{t("termsOfUse.conduct_b_i")}</Text>
          <Text>{t("termsOfUse.conduct_b_ii")}</Text>
          <Text>{t("termsOfUse.conduct_b_iii")}</Text>
          <Text>{t("termsOfUse.conduct_b_iv")}</Text>
          <Text>{t("termsOfUse.conduct_b_v")}</Text>
          <Text>{t("termsOfUse.conduct_b_vi")}</Text>
          <Text>{t("termsOfUse.conduct_b_vii")}</Text>
          <Text>{t("termsOfUse.conduct_b_viii")}</Text>
          <Text>{t("termsOfUse.conduct_b_ix")}</Text>
          <Text>{t("termsOfUse.conduct_b_x")}</Text>
          <Text>{t("termsOfUse.conduct_b_xi")}</Text>
          <Text>{t("termsOfUse.conduct_b_xii")}</Text>
          <Text>{t("termsOfUse.conduct_b_xiii")}</Text>
          <Text>{t("termsOfUse.conduct_b_xiv")}</Text>
          <Text>{t("termsOfUse.conduct_b_xv")}</Text>
          <Text>{t("termsOfUse.conduct_b_xvi")}</Text>
          </Stack>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">
            {t("termsOfUse.userauthentication")}
          </Text>
          <Text pb="4">{t("termsOfUse.userauthentication_a")}</Text>
          <Text pb="4">{t("termsOfUse.userauthentication_b")}</Text>
          <Text pb="4">{t("termsOfUse.userauthentication_c")}</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">
            {t("termsOfUse.intellectualpropertyandconfidentiality")}
          </Text>
          <Text pb="4">{t("termsOfUse.intellectualpropertyandconfidentiality_a")}</Text>
          <Text pb="4">{t("termsOfUse.intellectualpropertyandconfidentiality_b")}</Text>
          <Text pb="4">{t("termsOfUse.intellectualpropertyandconfidentiality_c")}</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">
            {t("termsOfUse.materialandlinksonthesite")}
          </Text>
          <Text pb="4">{t("termsOfUse.materialandlinksonthesite_a")}</Text>          
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">
            {t("termsOfUse.limitationofliabilityanddisclaimer")}
          </Text>
          <Text pb="4">{t("termsOfUse.limitationofliabilityanddisclaimer_a")}</Text>
          <Text pb="4">{t("termsOfUse.limitationofliabilityanddisclaimer_b")}</Text>
          <Text pb="4">{t("termsOfUse.limitationofliabilityanddisclaimer_c")}</Text>
          <Text pb="4">{t("termsOfUse.limitationofliabilityanddisclaimer_d")}</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">
            {t("termsOfUse.indemnification")}
          </Text>
          <Stack spacing="4">
            <Text>{t("termsOfUse.indemnification_a")}</Text>
            <Text pl="4">{t("termsOfUse.indemnification_i")}</Text>
            <Text pl="4">{t("termsOfUse.indemnification_ii")}</Text>
            <Text pl="4">{t("termsOfUse.indemnification_iii")}</Text>            
          </Stack>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">
            {t("termsOfUse.forcemajeure")}
          </Text>
            <Text>{t("termsOfUse.forcemajeure_a")}</Text>            
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">
            {t("termsOfUse.governinglaw")}
          </Text>
          <Text>{t("termsOfUse.governinglaw_a")}</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">
            {t("termsOfUse.severabilityandwaiver")}
          </Text>
          <Text pb="4">{t("termsOfUse.severabilityandwaiver_a")}</Text>
          <Text pb="4">{t("termsOfUse.severabilityandwaiver_b")}</Text>
        </ListItem>        
      </OrderedList>
      <Text pb="4">{t("termsOfUse.terms_footer")}</Text>      
    </Box>
  );
};

export default TermsOfUsePage;
