import { Box } from "@chakra-ui/layout";
import { Link, HStack, Spacer } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation("common");
  return (
    <HStack bg="brand.darkblue" px="10" py="4" marginTop="auto">
      <Box color="white">{t("footer.copyright")}</Box>
      <Spacer />
      <HStack spacing={"50px"}>
      <Link color="white" as={RouterLink} to="/termsofuse" isExternal>
        {t("Terms & Conditions")}
      </Link>
      <Link color="white" target="_blank" href="https://www.moneris.com/en/legal/privacy-notice">
        {t("Privacy Notice")}
      </Link>
      </HStack> 
    </HStack>
  );
};

export default Footer;
